%heading-large {
    font-size: var(--heading-large-font-size);
    color: rgb(var(--heading-large-color));
    font-family: var(--heading-large-font-family);
    font-weight: var(--heading-large-font-weight);
    line-height: var(--heading-large-line-height);
    letter-spacing: var(--heading-large-letter-spacing, inherit);
}

%heading-medium {
    font-size: var(--heading-medium-font-size);
    color: rgb(var(--heading-medium-color));
    font-family: var(--heading-medium-font-family);
    font-weight: var(--heading-medium-font-weight);
    line-height: var(--heading-medium-line-height);
    letter-spacing: var(--heading-medium-letter-spacing, inherit);
}

%heading-small {
    font-size: var(--heading-small-font-size);
    color: rgb(var(--heading-small-color));
    font-family: var(--heading-small-font-family);
    font-weight: var(--heading-small-font-weight);
    line-height: var(--heading-small-line-height);
    letter-spacing: var(--heading-small-letter-spacing, inherit);
}

%heading-x-small {
    font-size: var(--heading-x-small-font-size);
    color: rgb(var(--heading-x-small-color));
    font-family: var(--heading-x-small-font-family);
    font-weight: var(--heading-x-small-font-weight);
    line-height: var(--heading-x-small-line-height);
    letter-spacing: var(--heading-x-small-letter-spacing, inherit);
    text-transform: var(--heading-x-small-text-transform, inherit);
}

%article-heading-large {
    font-size: var(--article-heading-large-font-size);
    color: rgb(var(--article-heading-large-color));
    font-family: var(--article-heading-large-font-family);
    font-weight: var(--article-heading-large-font-weight);
    line-height: var(--article-heading-large-line-height);
    letter-spacing: var(--article-heading-large-letter-spacing, inherit);
}

%article-heading-medium {
    font-size: var(--article-heading-medium-font-size);
    color: rgb(var(--article-heading-medium-color));
    font-family: var(--article-heading-medium-font-family);
    font-weight: var(--article-heading-medium-font-weight);
    line-height: var(--article-heading-medium-line-height);
    letter-spacing: var(--article-heading-medium-letter-spacing, inherit);
}

%article-heading-small {
    font-size: var(--article-heading-small-font-size);
    color: rgb(var(--article-heading-small-color));
    font-family: var(--article-heading-small-font-family);
    font-weight: var(--article-heading-small-font-weight);
    line-height: var(--article-heading-small-line-height);
    letter-spacing: var(--article-heading-small-letter-spacing, inherit);
}

%paragraph-large {
    font-size: var(--paragraph-large-font-size);
    color: rgb(var(--paragraph-large-color));
    font-family: var(--paragraph-large-font-family);
    font-weight: var(--paragraph-large-font-weight);
    line-height: var(--paragraph-large-line-height);
    letter-spacing: var(--paragraph-large-letter-spacing, inherit);
}

%paragraph-medium {
    font-size: var(--paragraph-medium-font-size);
    color: rgb(var(--paragraph-medium-color));
    font-family: var(--paragraph-medium-font-family);
    font-weight: var(--paragraph-medium-font-weight);
    line-height: var(--paragraph-medium-line-height);
    letter-spacing: var(--paragraph-medium-letter-spacing, inherit);
}

%caption-medium {
    font-size: var(--caption-medium-font-size);
    color: rgb(var(--caption-medium-color));
    font-family: var(--caption-medium-font-family);
    font-weight: var(--caption-medium-font-weight);
    line-height: var(--caption-medium-line-height);
    letter-spacing: var(--caption-medium-letter-spacing, inherit);
    text-transform: var(--caption-medium-text-transform, inherit);
}

%caption-small {
    font-size: var(--caption-small-font-size);
    color: rgb(var(--caption-small-color));
    font-family: var(--caption-small-font-family);
    font-weight: var(--caption-small-font-weight);
    line-height: var(--caption-small-line-height);
    letter-spacing: var(--caption-small-letter-spacing, inherit);
    text-transform: var(--caption-small-text-transform, inherit);
}

%tag-medium {
    font-size: var(--tag-medium-font-size);
    color: rgb(var(--tag-medium-color));
    font-family: var(--tag-medium-font-family);
    font-weight: var(--tag-medium-font-weight);
    line-height: var(--tag-medium-line-height);
    letter-spacing: var(--tag-medium-letter-spacing, inherit);
    text-transform: var(--tag-medium-text-transform, inherit);
}

%tag-small {
    font-size: var(--tag-small-font-size);
    color: rgb(var(--tag-small-color));
    font-family: var(--tag-small-font-family);
    font-weight: var(--tag-small-font-weight);
    line-height: var(--tag-small-line-height);
    letter-spacing: var(--tag-small-letter-spacing, inherit);
    text-transform: var(--tag-small-text-transform, inherit);
}

%link-medium {
    font-size: var(--link-medium-font-size);
    color: rgb(var(--link-medium-color));
    font-family: var(--link-medium-font-family);
    font-weight: var(--link-medium-font-weight);
    line-height: var(--link-medium-line-height);
    letter-spacing: var(--link-medium-letter-spacing, inherit);
    text-transform: var(--link-medium-text-transform, inherit);
}

%link-small {
    font-size: var(--link-small-font-size);
    color: rgb(var(--link-small-color));
    font-family: var(--link-small-font-family);
    font-weight: var(--link-small-font-weight);
    line-height: var(--link-small-line-height);
    letter-spacing: var(--link-medium-letter-spacing, inherit);
}

%link-list {
    font-size: var(--link-list-font-size);
    color: rgb(var(--link-list-color));
    font-family: var(--link-list-font-family);
    font-weight: var(--link-list-font-weight);
    line-height: var(--link-list-line-height);
    letter-spacing: var(--link-list-letter-spacing, inherit);
    text-transform: var(--link-list-text-transform, inherit);
}
