$-z-index-line: 0;
$-z-index-button: 1;

.line-behind-button {
    button {
        z-index: $-z-index-button;
    }

    hr {
        display: block;
        position: absolute;
        top: 50%;
        z-index: $-z-index-line;
    }
}

.ReadMoreButton {
    display: block;
    margin: var(--largePadding) auto;
    background-color: transparent;
    transition: all 0.2s ease-in-out;

    [data-theme='br'] &,
    [data-theme='gp'] & {
        text-transform: uppercase;
        border: 0;
        border-radius: 0;
    }

    :global(.Icon) {
        display: inline-flex !important;
        margin: 0;
        vertical-align: initial !important;
        fill: rgb(var(--mainColor));

        [data-theme='vi'] & {
            fill: rgb(var(--invertedTextColor));
        }
    }

    &:hover {
        background-color: transparent;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        color: rgb(var(--mainColor));

        [data-theme='vp'] &,
        [data-theme='vpbe'] &,
        [data-theme='vn'] & {
            background-color: rgb(var(--linkColor));
            color: rgb(var(--invertedTextColor));
        }

        [data-theme='gp'] &,
        [data-theme='br'] & {
            color: rgb(var(--mainHoverColor));
        }
    }

    &.highlighted {
        display: block;
        position: relative;
        color: rgb(var(--invertedTextColor));
        border: 2px solid rgb(var(--mainColor));

        [data-theme='br'] & {
            margin: var(--standardPadding) auto var(--largePadding) auto;
            padding: calc(var(--standardPadding) / 2) var(--largePadding);
            font-size: 1em;
            line-height: 1.5em;
            text-transform: capitalize;
            border-radius: var(--standardRadius);
        }

        &:hover {
            background-color: rgb(var(--mainColor));
            transition: all 0.2s ease-in-out;
            text-decoration: none;

            [data-theme='br'] & {
                background-color: rgb(var(--mainHoverColor));
                color: rgb(var(--invertedTextColor));
                border: 2px solid rgb(var(--mainHoverColor));
            }
        }
    }
}
