@use 'mixins';

.ProTag {
    display: flex;
    flex-direction: row;
    height: 1.25em;

    :global(.Logo) {
        display: block;
        width: 100%;
        height: 100%;
        transform: translateX(1px);

        svg {
            width: auto;
        }
    }

    .pro-text {
        @extend %heading-x-small;

        background: rgb(var(--invertedTextColor));
        padding: 0 var(--smallPadding) 0 var(--smallPadding);
        text-transform: uppercase;
        color: rgb(var(--textColor));
        line-height: initial;
        white-space: nowrap;
    }
}
