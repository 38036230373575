@use 'mixins';
@use 'sass:color';

.OddsBlock {
    @extend %tag-medium;

    display: flex;
    align-items: flex-end;
    color: rgb(var(--invertedTextColor));
    flex-direction: column;
    text-align: center;
    order: 4;

    a,
    a:hover {
        text-decoration: none;
        color: inherit;
    }

    :global(.betting-disabled) & {
        display: none;
    }

    .odd-group {
        display: flex;
        padding-left: 0;

        + .odd-group {
            margin-top: calc(var(--tinyPadding) * 0.7);
        }

        [data-theme='vi'] & {
            border-radius: 2.2em;
            padding: var(--smallPadding);
            margin-bottom: var(--tinyPadding);
        }
    }

    li {
        height: 2.2em;
        line-height: 2.2em;
        margin-bottom: 0 !important;
        white-space: nowrap;

        + li {
            display: flex;
            margin-left: calc(var(--tinyPadding) * 0.5);

            [data-theme='vi'] & {
                background-color: rgb(var(--bettingColor));
                border-radius: 1em;
                overflow: hidden;
                display: flex;
                align-items: center;
            }
        }
    }

    // the small number next to odds [1,x,2]
    .odd-key {
        display: inline-block;
        min-width: 2em;
        border-radius: var(--standardRadius) 0 0 var(--standardRadius);
        background-color: rgb(var(--darkerBettingColor));
        color: rgba(var(--invertedTextColor), 0.6);

        [data-theme='vi'] & {
            width: 1.5em;
            height: 1.5em;
            margin: 0 0.2em 0 0.4em;
            min-width: auto;
            line-height: 1.6em;
            border-radius: 1em;
            position: relative;
        }
    }

    .odd-value {
        display: inline-block;
        min-width: calc(var(--hugePadding) * 1.5);
        background-color: rgb(var(--bettingColor));
        border-radius: 0 var(--standardRadius) var(--standardRadius) 0;

        &:hover {
            background-color: rgb(var(--bettingHoverColor));
        }
    }

    .logo-wrapper {
        display: flex;
        width: calc(var(--hugePadding) * 1.5);
        overflow: hidden;
        padding: 0 1em;
        border-radius: var(--standardRadius);
        align-items: center;
        justify-content: center;
        line-height: 0;
    }

    img {
        width: 3.5em;
        height: auto;
    }

    :global(.Logo) {
        width: 3.5em;

        /* stylelint-disable-next-line selector-class-pattern */
        &:global(.eighteenPlus-logo) {
            width: 7em;
            height: 1.5em;
            margin-right: 0;
        }

        /* stylelint-disable-next-line selector-class-pattern */
        &:global(.eighteenPlus-logo-inline) {
            width: 10em;
        }
    }

    .stop-gambling {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.75em;
        width: 100%;
        color: rgba(var(--invertedTextColor), 0.65);

        :global(.Icon) {
            font-size: 1.5em;
        }

        [data-theme='vi'] & {
            position: absolute;
            z-index: 5;
            bottom: var(--tinyPadding);
            right: var(--standardPadding);
        }
    }

    &.has-dark-background {
        .logo-wrapper {
            background-color: rgba(255, 255, 255, 0.1);

            [data-theme='vi'] & {
                background-color: transparent;
                margin: 0 1em;
            }
        }

        .odd-group {
            [data-theme='vi'] & {
                background: rgba(var(--background), 0.2);
            }
        }
    }

    &.is-larger {
        [data-theme='vi'] & {
            width: auto;

            li {
                height: 2.4em;
                line-height: 2.4em;

                + li {
                    border-radius: 1.2em;
                }
            }

            .odd-value {
                min-width: 6.5em;
            }

            img {
                display: block;
                width: 4em;
                height: auto;
            }
        }

        .stop-gambling {
            position: relative;
            margin: var(--standardPadding) auto -1em auto;

            :global(.Logo) {
                /* stylelint-disable-next-line selector-class-pattern */
                &:global(.eighteenPlusInline-logo) {
                    display: block;
                    width: 14em;
                    height: 2.5em;
                }
            }
        }
    }

    &.is-compact {
        margin-top: var(--smallPadding);
        align-items: center;
        font-size: 0.75em;

        .odd-value {
            min-width: calc(var(--hugePadding) * 1.3);
        }

        .logo-wrapper {
            padding: 0;
        }
    }

    &.is-match-of-the-day {
        .logo-wrapper {
            width: calc(var(--hugePadding) * 2.5);
        }

        .odd-group {
            [data-theme='vp'] &,
            [data-theme='vn'] & {
                background: rgb(var(--background));
                border-radius: var(--standardRadius);
                padding: 0.5em;
                box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
                margin-top: var(--tinyPadding);
                margin-bottom: 0;
            }
        }

        .odd-value {
            min-width: calc(var(--hugePadding) * 2.265);
        }

        [data-theme='vi'] & {
            width: auto;
        }
    }

    &.is-block {
        background-color: rgb(var(--sectionBack));
        border-radius: var(--standardRadius);
        padding-top: calc(var(--tinyPadding) * 0.5);
        margin-bottom: -#{var(--standardPadding)};

        .odd-group {
            place-content: flex-start;
            flex-wrap: wrap;
            width: 10em;
        }

        li {
            flex-basis: 50%;
            margin-left: 0;
            order: 1;

            &:last-child {
                margin-top: calc(var(--tinyPadding) * 0.5);
                order: 3;
            }
        }

        .odd-key,
        .odd-value {
            font-size: 0.857em;
        }

        .odd-key {
            min-width: 1.67em;
        }

        .odd-value {
            min-width: 3.67em;
        }

        .logo-wrapper {
            display: block;
            order: 2;
            box-sizing: border-box;
            width: auto;
            padding: 0;

            > a {
                display: flex;
                width: 66px;
                height: 58px;
                padding: var(--standardPadding);
                background-color: rgb(var(--invertedBackground));
                border-radius: var(--standardRadius);
                box-sizing: border-box;
                place-items: center;
            }

            img {
                width: 100%;
            }
        }

        .stop-gambling {
            height: var(--smallPadding);
            line-height: var(--tinyPadding);
            font-weight: 400;
            font-size: 8px;
            text-transform: uppercase;
            color: rgb(var(--lighterTextColor));
            letter-spacing: normal;
            padding: 0 var(--tinyPadding);
        }
    }

    &.is-larger .disclaimer-wrapper {
        width: 100%;
    }
}

@include mixins.responsive(m, below) {
    .OddsBlock {
        &.is-match-of-the-day {
            .odd-key {
                min-width: 1.67em;
            }

            .odd-value {
                min-width: 4.67em;
            }

            .logo-wrapper {
                width: auto;
            }
        }

        .odd-group {
            margin-left: 0;
        }

        .odd-key,
        .odd-value {
            [data-theme='vi'] & {
                font-size: 1em;
            }
        }

        .odd-key {
            min-width: 1.67em;
        }

        .odd-value {
            min-width: 3.67em;
        }

        .logo-wrapper {
            padding: 0 var(--tinyPadding);
        }

        &.has-limited-space {
            .odd-key {
                display: none;

                [data-theme='vi'] & {
                    display: inline-block;
                }
            }
        }

        &.is-block {
            .logo-wrapper {
                padding: 0;
            }
        }

        .stop-gambling {
            [data-theme='vi'] & {
                margin: 0 auto;
                left: 0;
                right: 0;
                display: table;
            }
        }

        [data-theme='vi'] & {
            padding-top: var(--standardPadding);
            padding-bottom: var(--tinyPadding);
            min-width: auto;
            flex-basis: 100%;
            max-width: 100%;
            align-items: center;

            .match {
                flex-wrap: wrap;
            }
        }
    }
}

@include mixins.responsive(s, below) {
    .OddsBlock {
        // Hide X odd on mobile
        &.has-limited-space {
            ul li:nth-child(3) {
                display: none;

                [data-theme='vi'] & {
                    display: flex;
                }
            }
        }
    }
}
